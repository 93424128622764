import React from "react";
import { MdOutlineEmail } from 'react-icons/md';
import './Contact.css';


export const Contact = () => {


    return(
    <div className="position">
    <div className="card">

    <a className="emailColor" href="mailto:ansukamara527@gmail.com">
    <MdOutlineEmail className="icon" /> <br/>
    </a>
    <br/>
    <a href="mailto:ansukamara527@gmail.com">
        <br/>
        <br/>
        <p className="fontSize"><strong>Send an Email</strong></p>
        
        <p className="log">
          <br />
          <a href="https://www.linkedin.com/in/ansukamara27974/">
          <img className="linkedinLogo" src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg" alt="LinkedIn Logo" />
          </a> 
          </p>
    
    </a>
    <br/>
    <br/>

    </div>
    </div>
    );
}