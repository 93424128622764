import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./NarBar.css";


export const NarBar = () => {
    const location = useLocation();


return (
    <div className="narbar">
    <div className="links">
     <Link to='/'  className={location.pathname === '/home' ? 'active-link' : ''}>
        Home
        </Link> 
        </div>

        <div className="links">
        <Link to='/about'  className={location.pathname === '/about' ? 'active-link' : ''}>
        About
        </Link> 
        </div>

        <div className="links">
        <Link to='/projects'  className={location.pathname === '/projects' ? 'active-link' : ''}>
        Projects
        </Link> 
        </div>

        <div className="links">
        <Link to='/skills'  className={location.pathname === '/skills' ? 'active-link' : ''}>
        Skills
        </Link> 
        </div>

        <div className="links">
        <Link to='/resume'  className={location.pathname === '/resume' ? 'active-link' : ''}>
        Resume
        </Link> 
        </div>

        <div className="links">
        <Link to='/contact'  className={location.pathname === '/contact' ? 'active-link' : ''}>
        Contact
        </Link> 
        </div>

    </div>
)
};