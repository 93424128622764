import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {NarBar} from "./NarBar/NarBar";
import {Home} from "./Pages/Home";
import {About} from "./Pages/About";
import {Projects} from "./Pages/Projects";
import {Skills} from "./Pages/Skills";
import {Resume} from "./Pages/Resume";
import {Contact} from "./Pages/Contact";
import './App.css';


function App() {
  return (
    <div className="App">
      <Router>
          <NarBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/skills" element={<Skills />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
      
          <br />
          <br />
        
        </Router>
    </div>
  );
}

export default App;
