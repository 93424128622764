import React from "react";
import './Projects.css';



export const Projects = () => {

    return (
        <div>
           
            
            <br />
            <br />
            <br />
            <br/>
            <div className="project2">
        <h1 className="project1">Projects will soon be available on this website.</h1>
           </div>
        <br />
        <br />
        <br />
        <br/>  
       
        
   </div>
        )
}