import React from "react";
import './Home.css';
import photo from '../Asset/ansu.png';
import { Main } from "../Main";



export const Home = (props) => {

    return (
         <div className="broom">
        <div className="kard">
        <div className="front">

        
        <img className="photo" src={photo} alt="" />
        
        <h2> <strong>Ansu Kamara</strong> </h2>
    

        <p><strong> Junior Software Engineer</strong></p>
        <Main />

        </div>
        </div>
        </div>
    );
}