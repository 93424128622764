import React from "react";
import './Resume.css';


export const Resume = () => {


    return (
        <div className="topic">
        
            
            <a href="https://drive.google.com/file/d/1O4-Ozk604WPBlaYOIprIZXYDbmDKD2Cn/view?usp=drivesdk">
            <p  className="button">
                DOWNLOAD CV 
            </p>
            </a>
            
            <br />
            <br/>
            <br/>
            <br/>
    
        </div>
    )
}