import React from "react";
import { Skills } from "./Pages/Skills";

import { Resume } from "./Pages/Resume";

export const Main = () => {
    return (
        <div>
     < Skills />
     < Resume />
     
    </div>
    )
}