import React from "react";
import './About.css';


export const About = () => {

    return (
        <div className="cardAbout">
            <br/>
            <br/>
        <div className="carrd">
            <h1 className="name">Hello, My name is <strong>Ansu!</strong></h1>
            <p> Junior React Native and JS developer here. 
                 Although I currently live and work in Cyprus, my parents and I were both born and raised in Sierra Leone.
                  As a junior developer, my objective is to improve my knowledge of HTML, CSS,
                   and Javascript frameworks (such as React and React Native) for creating websites 
                   and mobile applications for both Android and iOS devices. 
                   I'm working on a lot of projects right now, and
                    they will soon be displayed on this website for employers to see.
             </p>
             <br />
             <br />
             <br />
             <br />
             <br/>
             <br/>
        </div>
        </div>


    )
}