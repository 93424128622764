import React from "react";
import { BsFillPatchCheckFill } from 'react-icons/bs';
import './Skills.css';


export const Skills = () => {

    return (
        <div>
        <div>
        <div>
         <section >
            <br/>
        <h3 className="heading">Technical Skills I have:</h3>
        <div className="gridd-container">
        <article className="gridd" >
        <BsFillPatchCheckFill  />
        <h4>HTML</h4>
        </article>
        <article className="gridd" >
        <BsFillPatchCheckFill  />
        <h4>CSS</h4>
        </article>
        <article className="gridd" >
        <BsFillPatchCheckFill  />
        <h4>Javascript</h4>
        </article>
        <article className="gridd" >
        <BsFillPatchCheckFill  />
        <h4>React</h4>
        </article>
        <article className="gridd" >
        <BsFillPatchCheckFill  />
        <h4>React Native</h4>
        </article>
        <article className="gridd" >
        <BsFillPatchCheckFill  />
        <h4>Redux</h4>
        </article>
        <article className="gridd" >
        <BsFillPatchCheckFill  />
        <h4>Firebase</h4>
        </article>
        <article className="gridd" >
        <BsFillPatchCheckFill  />
        <h4>Next Js</h4>
        </article>
        <article className="gridd" >
        <BsFillPatchCheckFill  />
        <h4>TypeScript</h4>
        </article>
        <article  className="gridd">
        <BsFillPatchCheckFill  />
        <h4>Git</h4>
        </article>
        </div>
        </section>
        </div>

    



       <div>
        <h3>Soft Skills I have:</h3>
        <div className="grid-container">
        <article className="grid" >
            <BsFillPatchCheckFill  />
            <h4>Time Management</h4>
        </article>
        <article className="grid">
            <BsFillPatchCheckFill  />
            <h4>Empathy</h4>
        </article>
        <article className="grid" >
            <BsFillPatchCheckFill  />
            <h4>Problem Solving</h4>
        </article>
        <article className="grid" >
            <BsFillPatchCheckFill />
            <h4>Emotional Intelligence</h4>
        </article>
        <article className="grid">
        <BsFillPatchCheckFill  />
        <h4>Collaboration</h4>
        </article>
        <article className="grid">
            <BsFillPatchCheckFill  />
            <h4>Active Listening</h4>
        </article>
        </div>  
</div>
</div>

</div>

        

    )
}